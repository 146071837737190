import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &.hero-image-content {
    padding: 0;
  }

  &:first-child {
    margin-bottom: 1rem;
  }

  & > * {
    width: 100%;
  }
`

export const BoxTextAligment = styled.div`
  display: grid;
  gap: 1rem;
`

export const Separator = styled.hr`
  width: 100%;
  color: var(--gray400);
  border-width: 0;
  border-bottom-width: 0.5rem;
  margin: 1rem 0 2rem;
  align-self: center;
`

export const PlansCardList = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
`
