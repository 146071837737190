import React, { ReactElement } from 'react'

import { parse } from 'query-string'

import { RouteComponentProps, useLocation } from '@reach/router'

import { InterCelLP } from '../LandingPages/Intercel'
import { Error } from '../Error'

export const Home: React.FC<RouteComponentProps> = () => {
  const location = useLocation()
  const searchParams = parse(location.search, {}) as { [key: string]: string }

  const landingPages: { [key: string]: ReactElement } = {
    interCel: <InterCelLP />,
    default: <Error />,
  }

  return landingPages[searchParams.productId] || landingPages.default
}
