import React, { useCallback, useEffect } from 'react'

import { Button } from '@inter/inter-ui/components/Button'
import { Text } from '@inter/inter-ui/components/Text'
import { navigate } from '@reach/router'

import { BoxTextAligment, Content } from './styles'
import HeroImage from './assets/images/HeroImageError.svg'
import { AppContainer } from '../../styles/GlobalStyles'
import { AppBar } from '../../components/AppBar'

export const Error: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const sendToHome = useCallback(() => {
    navigate(0)
  }, [])

  return (
    <>
      <AppBar titleRoute="Inter Pass" goBackNative />
      <AppContainer paddingTopRem={2.5}>
        <Content className="hero-image-content">
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content style={{ marginBottom: '6rem' }}>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              Ocorreu um erro
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Ocorreu um erro inesperado, nossa equipe já está trabalhando para solucionar este
              problema, por favor tente novamente mais tarde.
            </Text>
          </BoxTextAligment>
        </Content>

        <Content
          style={{
            position: 'fixed',
            bottom: 0,
            backgroundColor: 'var(--white100)',
            marginBottom: 0,
            paddingBottom: '1rem',
            paddingTop: '0.5rem',
            borderWidth: 0,
            alignSelf: 'center',
          }}
        >
          <Button style={{ width: '100%' }} onClick={() => sendToHome()}>
            Tentar novamente
          </Button>
        </Content>
      </AppContainer>
    </>
  )
}
