import styled from 'styled-components'

import { Text } from '@inter/inter-ui/components/Text'
import { BottomSheet } from '@inter/inter-ui/components/BottomSheet'

export const InterCelPlanBox = styled.div`
  width: 48%;
  min-width: 150px;
  margin-right: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--gray200);
  border-radius: 0.5rem;
  padding: 1rem;
  padding-bottom: 1rem;
`

export const InterCelPlanBoxAppsAvailable = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-style: none none dashed;
  border-color: var(--gray200);

  & img {
    height: 100%;
  }
`

export const InterCelPlanBoxHeader = styled.div``
export const InterCelPlanBoxFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`

export const InterCelPlanBoxDataPlan = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'headline-big',
  semiBold: true,
})``

export const InterCelPlanBoxBenefits = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`

export const InterCelPlanBoxPrice = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'body-3',
  bold: true,
})``

export const InterCelPlanBoxCashback = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'caption-2',
  bold: true,
})`
  color: var(--success500);
`

export const BackgroundPending = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: var(--alert100);
  border-radius: 0.5rem;
  width: 100%;
  gap: 1rem;
  padding: 1rem;

  .alert-text {
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
    flex-direction: column;
  }
`

export const BottomSheetPlan = styled(BottomSheet)`
  display: grid;
  gap: 1rem;
`
