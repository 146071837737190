import React, { useCallback } from 'react'

import HomeIcon from 'inter-frontend-svgs/lib/orangeds/SM/home'
import ControlsIcon from 'inter-frontend-svgs/lib/orangeds/SM/controls'
import PhoneIcon from 'inter-frontend-svgs/lib/orangeds/SM/phone'
import CashbackIcon from 'inter-frontend-svgs/lib/orangeds/SM/cashback'
import PhoneCallIcon from 'inter-frontend-svgs/lib/orangeds/SM/phone-call'
import WhatsappAppIcon from 'inter-frontend-svgs/lib/orangeds/SM/whatsapp'
import InterAppIcon from 'inter-frontend-svgs/lib/orangeds/XL/notification center'
import WazeAppIcon from 'inter-frontend-svgs/lib/orangeds/SM/waze'
import MoovitAppIcon from 'inter-frontend-svgs/lib/orangeds/SM/moovit'
import { interWbNavigate } from 'inter-webview-bridge'

import { Text } from '@inter/inter-ui/components/Text'
import { Babi } from '@inter/inter-ui/components/Babi'
import { Button } from '@inter/inter-ui/components/Button'

import { AppBar } from '../../../components/AppBar'
import { AppContainer } from '../../../styles/GlobalStyles'
import { BoxTextAligment, Content, PlansCardList, Separator } from './styles'
import HeroImage from './assets/images/HeroImageHome.png'
import { bridgeNative } from '../../../App'
import { Advantage } from '../../../components/Advantage'
import { InterCelPlan } from '../../../components/InterCelPlan'

export const InterCelLP: React.FC = () => {
  const handleTermsOfUse = useCallback(() => {
    interWbNavigate.openNativeBrowser(
      'https://marketplace.bancointer.com.br/intercel/bff/pdfs/termosCondicoesIntercel.pdf',
    )
  }, [])

  const openBabi = useCallback(() => {
    bridgeNative.execute({ action: 'sendToBabi' })
  }, [])

  const whatsappAppIconImg = <WhatsappAppIcon height={20} width={20} />
  const interAppIconImg = <InterAppIcon height={20} width={20} color="var(--orange500)" />
  const wazeAppIconImg = <WazeAppIcon height={20} width={20} />
  const moovitAppIconImg = <MoovitAppIcon height={20} width={20} />

  const interCelPlans = [
    {
      internetDataPlan: '4GB',
      availableAppsIcons: [whatsappAppIconImg, interAppIconImg, wazeAppIconImg, moovitAppIconImg],
      price: '30,00 / mês',
      cashback: '7% de cashback',
    },
    {
      internetDataPlan: '6GB',
      availableAppsIcons: [whatsappAppIconImg, interAppIconImg, wazeAppIconImg, moovitAppIconImg],
      price: '40,00 / mês',
      cashback: '7% de cashback',
    },
    {
      internetDataPlan: '8GB',
      availableAppsIcons: [whatsappAppIconImg, interAppIconImg, wazeAppIconImg, moovitAppIconImg],
      price: '50,00 / mês',
      cashback: '7% de cashback',
    },
    {
      internetDataPlan: '13GB',
      availableAppsIcons: [whatsappAppIconImg, interAppIconImg, wazeAppIconImg, moovitAppIconImg],
      price: '75,00 / mês',
      cashback: '7% de cashback',
    },
  ]

  return (
    <>
      <AppBar titleRoute="Inter Cel" goBackNative />
      <AppContainer paddingTopRem={2.5}>
        <Content className="hero-image-content">
          <img alt="hero_image" src={HeroImage} />
        </Content>
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-big" semiBold>
              Inter Cel, a operadora do Inter
            </Text>
            <Text color="typography" colorWeight={400} variant="body-3">
              Conheça os nossos planos mensais, sem fidelidade, com a cobertura da Vivo, a melhor
              cobertura de internet móvel do Brasil.
            </Text>
          </BoxTextAligment>
        </Content>
        <Content>
          <Text
            color="typography"
            colorWeight={500}
            variant="headline-h3"
            semiBold
            style={{ marginBottom: '0.5rem' }}
          >
            Suas vantagens na Inter Cel:
          </Text>
          <Advantage
            icon={<ControlsIcon height={24} width={24} color="var(--gray500)" />}
            title="Você no controle"
            content="Você pode gerenciar seu consumo, mudar para um plano que faça mais sentido pra você, contratar pacote adicional e acompanhar o seu pedido de portabilidade"
          />
          <Advantage
            icon={<HomeIcon height={24} width={24} color="var(--gray500)" />}
            title="Maior conforto"
            content="Compre pelo app e receba o seu chip em casa"
          />
          <Advantage
            icon={<PhoneIcon height={24} width={24} color="var(--gray500)" />}
            title="Traga seu número e ganhe bônus"
            content="Após a ativação do chip, você pode pedir a sua portabilidade a qualquer momento e ainda recebe mais internet (exceto clientes Vivo e de suas operadoras móveis virtuais)"
          />
          <Advantage
            icon={<CashbackIcon height={24} width={24} color="var(--gray500)" />}
            title="Cashback na sua conta"
            content="Além de internet sem cortes e ligações para qualquer número, você também tem cashback nas recargas."
          />
        </Content>
        <Separator />
        <Content>
          <BoxTextAligment>
            <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
              Planos Inter Cel
            </Text>
            <Text color="typography" colorWeight={400} variant="caption-1">
              Encontre o que cabe no seu bolso. Válidos por 30 dias.
            </Text>
          </BoxTextAligment>
          <Advantage
            icon={<PhoneCallIcon height={24} width={24} color="var(--gray500)" />}
            title="Ligações ilimitadas"
            content="ligações ilimitadas para qualquer ddd e operadora em todos os planos."
          />
        </Content>
        <PlansCardList>
          {interCelPlans.map((interCelPlanProps) => (
            <InterCelPlan {...interCelPlanProps} />
          ))}
        </PlansCardList>

        <Content>
          <Button
            style={{
              display: 'flex',
              alignSelf: 'flex-start',
              padding: 0,
              marginBottom: '1.5rem',
              width: 'auto',
            }}
            variant="link"
            onClick={handleTermsOfUse}
          >
            <Text color="primary" colorWeight={500} variant="caption-1" bold>
              Ver Termos e Condições
            </Text>
          </Button>
          <Babi onClick={openBabi} />
        </Content>
      </AppContainer>
    </>
  )
}
