import React, { useCallback, useState } from 'react'

import SmartphoneIcon from 'inter-frontend-svgs/lib/orangeds/SM/smartphones'
import WarningIcon from 'inter-frontend-svgs/lib/orangeds/SM/attention'

import { Text } from '@inter/inter-ui/components/Text'
import { Button } from '@inter/inter-ui/components/Button'
import { BottomSheet } from '@inter/inter-ui/components/BottomSheet'

import {
  InterCelPlanBoxHeader,
  InterCelPlanBoxFooter,
  InterCelPlanBoxAppsAvailable,
  InterCelPlanBox,
  InterCelPlanBoxPrice,
  InterCelPlanBoxBenefits,
  InterCelPlanBoxDataPlan,
  InterCelPlanBoxCashback,
  BackgroundPending,
} from './styles'
import { Advantage } from '../Advantage'
import { bridgeNative } from '../../App'
// import { bridgeNative } from '../../App'

interface Props extends React.HTMLAttributes<unknown> {
  internetDataPlan: string
  availableAppsIcons: JSX.Element[]
  price: string
  cashback: string
}

export const InterCelPlan: React.FC<Props> = ({
  internetDataPlan,
  availableAppsIcons,
  price,
  cashback,
  ...props
}) => {
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false)
  const openDeepLinkPage = useCallback((deeplink) => {
    bridgeNative.execute({
      action: 'openDeeplink',
      metadata: {
        url: deeplink,
      },
    })
  }, [])
  return (
    <InterCelPlanBox {...props}>
      <InterCelPlanBoxHeader>
        <InterCelPlanBoxDataPlan>{internetDataPlan}</InterCelPlanBoxDataPlan>

        <InterCelPlanBoxBenefits>
          <Text color="typography" colorWeight={500} variant="caption-2">
            Apps gratuitos:
          </Text>
          <InterCelPlanBoxAppsAvailable>
            {availableAppsIcons.map((availableAppIcon) => availableAppIcon)}
          </InterCelPlanBoxAppsAvailable>
        </InterCelPlanBoxBenefits>
      </InterCelPlanBoxHeader>
      <InterCelPlanBoxFooter>
        <InterCelPlanBoxPrice>R$ {price}</InterCelPlanBoxPrice>
        <InterCelPlanBoxCashback>{cashback}</InterCelPlanBoxCashback>
      </InterCelPlanBoxFooter>
      <Button onClick={() => setShowBottomSheet(true)} size="small">
        Saiba mais
      </Button>

      {showBottomSheet && (
        <BottomSheet onClose={() => setShowBottomSheet(false)}>
          <div style={{ display: 'grid', gap: '1.5rem' }}>
            <Text color="typography" colorWeight={500} variant="headline-h3" semiBold>
              Exclusivo da conta completa
            </Text>
            <Advantage
              style={{ marginTop: 0 }}
              icon={<SmartphoneIcon height={24} width={24} color="var(--gray500)" />}
              title="Inter Cel"
              content="Ao abrir sua conta completa, que inclui a conta corrente, você pode contratar e recarregar seu Inter Cel direto pelo app, aproveitando os melhores planos."
            />
            <BackgroundPending>
              <div style={{ display: 'flex', alignSelf: 'flex-start' }}>
                <WarningIcon height={16} width={16} color="var(--alert500)" />
              </div>
              <div>
                <div className="alert-text">
                  <Text color="typography" colorWeight={500} variant="caption-1" bold>
                    O que você já pode usar
                  </Text>
                  <Text color="typography" colorWeight={500} variant="caption-1">
                    Quer aproveitar o Inter sem uma conta completa? Use os serviços da seção.
                    &quot;Disponivel para você&quot;
                  </Text>
                </div>
              </div>
            </BackgroundPending>
            <Button
              onClick={() => openDeepLinkPage('bancointer://onboarding/contaCompleta')}
              style={{ width: '100%' }}
            >
              Abrir conta completa
            </Button>
          </div>
        </BottomSheet>
      )}
    </InterCelPlanBox>
  )
}
