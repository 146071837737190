import React, { useEffect } from 'react'

import InterWebviewBridge, { interWbSession } from 'inter-webview-bridge'

import { initTheme, Theme } from '@inter/inter-ui'
import '@inter/inter-ui/inter-ui.css'
import { Router } from '@reach/router'

import { Home } from './pages/Home'

export const bridgeNative = InterWebviewBridge.getInstance()

function App() {
  useEffect(() => {
    interWbSession.editToolbar('', false)
    initTheme(Theme.PF)
  }, [])

  return (
    <div className="App">
      <Router>
        <Home path="/" />
      </Router>
    </div>
  )
}

export default App
